@font-face {
font-family: '__euclidCircularBRegular_f20a90';
src: url(/plp/_next/static/media/fa49acacb5255480-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__euclidCircularBRegular_Fallback_f20a90';src: local("Arial");ascent-override: 93.47%;descent-override: 25.05%;line-gap-override: 0.00%;size-adjust: 106.98%
}.__className_f20a90 {font-family: '__euclidCircularBRegular_f20a90', '__euclidCircularBRegular_Fallback_f20a90'
}

@font-face {
font-family: '__proximaNovaABlack_311e1f';
src: url(/plp/_next/static/media/810366759b60cf1d-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__proximaNovaABlack_Fallback_311e1f';src: local("Arial");ascent-override: 84.99%;descent-override: 38.07%;line-gap-override: 0.00%;size-adjust: 107.09%
}.__className_311e1f {font-family: '__proximaNovaABlack_311e1f', '__proximaNovaABlack_Fallback_311e1f'
}

.PresetDateRangePicker_panel {
  padding: 0 22px 11px
}
.PresetDateRangePicker_button {
  position: relative;
  height: 100%;
  text-align: center;
  background: 0 0;
  border: 2px solid #00a699;
  color: #00a699;
  padding: 4px 12px;
  margin-right: 8px;
  font: inherit;
  font-weight: 700;
  line-height: normal;
  overflow: visible;
  box-sizing: border-box;
  cursor: pointer
}
.PresetDateRangePicker_button:active {
  outline: 0
}
.PresetDateRangePicker_button__selected {
  color: #fff;
  background: #00a699
}
.SingleDatePickerInput {
  display: inline-block;
  background-color: #fff
}
.SingleDatePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb
}
.SingleDatePickerInput__rtl {
  direction: rtl
}
.SingleDatePickerInput__disabled {
  background-color: #f2f2f2
}
.SingleDatePickerInput__block {
  display: block
}
.SingleDatePickerInput__showClearDate {
  padding-right: 30px
}
.SingleDatePickerInput_clearDate {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%)
}
.SingleDatePickerInput_clearDate__default:focus,
.SingleDatePickerInput_clearDate__default:hover {
  background: #dbdbdb;
  border-radius: 50%
}
.SingleDatePickerInput_clearDate__small {
  padding: 6px
}
.SingleDatePickerInput_clearDate__hide {
  visibility: hidden
}
.SingleDatePickerInput_clearDate_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}
.SingleDatePickerInput_clearDate_svg__small {
  height: 9px
}
.SingleDatePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}
.SingleDatePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}
.SingleDatePicker {
  position: relative;
  display: inline-block
}
.SingleDatePicker__block {
  display: block
}
.SingleDatePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}
.SingleDatePicker_picker__rtl {
  direction: rtl
}
.SingleDatePicker_picker__directionLeft {
  left: 0
}
.SingleDatePicker_picker__directionRight {
  right: 0
}
.SingleDatePicker_picker__portal {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}
.SingleDatePicker_picker__fullScreenPortal {
  background-color: #fff
}
.SingleDatePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}
.SingleDatePicker_closeButton:focus,
.SingleDatePicker_closeButton:hover {
  color: darken(#cacccd,10%);
  text-decoration: none
}
.SingleDatePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.DayPickerKeyboardShortcuts_buttonReset {
  background: 0 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  padding: 0;
  cursor: pointer;
  font-size: 14px
}
.DayPickerKeyboardShortcuts_buttonReset:active {
  outline: 0
}
.DayPickerKeyboardShortcuts_show {
  width: 33px;
  height: 26px;
  position: absolute;
  z-index: 2
}
.DayPickerKeyboardShortcuts_show::before {
  content: "";
  display: block;
  position: absolute
}
.DayPickerKeyboardShortcuts_show__bottomRight {
  bottom: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-top: 26px solid transparent;
  border-right: 33px solid #00a699;
  bottom: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__bottomRight:hover::before {
  border-right: 33px solid #008489
}
.DayPickerKeyboardShortcuts_show__topRight {
  top: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__topRight::before {
  border-bottom: 26px solid transparent;
  border-right: 33px solid #00a699;
  top: 0;
  right: 0
}
.DayPickerKeyboardShortcuts_show__topRight:hover::before {
  border-right: 33px solid #008489
}
.DayPickerKeyboardShortcuts_show__topLeft {
  top: 0;
  left: 0
}
.DayPickerKeyboardShortcuts_show__topLeft::before {
  border-bottom: 26px solid transparent;
  border-left: 33px solid #00a699;
  top: 0;
  left: 0
}
.DayPickerKeyboardShortcuts_show__topLeft:hover::before {
  border-left: 33px solid #008489
}
.DayPickerKeyboardShortcuts_showSpan {
  color: #fff;
  position: absolute
}
.DayPickerKeyboardShortcuts_showSpan__bottomRight {
  bottom: 0;
  right: 5px
}
.DayPickerKeyboardShortcuts_showSpan__topRight {
  top: 1px;
  right: 5px
}
.DayPickerKeyboardShortcuts_showSpan__topLeft {
  top: 1px;
  left: 5px
}
.DayPickerKeyboardShortcuts_panel {
  overflow: auto;
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 2px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  padding: 22px;
  margin: 33px;
  text-align: left
}
.DayPickerKeyboardShortcuts_title {
  font-size: 16px;
  font-weight: 700;
  margin: 0
}
.DayPickerKeyboardShortcuts_list {
  list-style: none;
  padding: 0;
  font-size: 14px
}
.DayPickerKeyboardShortcuts_close {
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 2
}
.DayPickerKeyboardShortcuts_close:active {
  outline: 0
}
.DayPickerKeyboardShortcuts_closeSvg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
.DayPickerKeyboardShortcuts_closeSvg:focus,
.DayPickerKeyboardShortcuts_closeSvg:hover {
  fill: #82888a
}
.CalendarDay {
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  text-align: center
}
.CalendarDay:active {
  outline: 0
}
.CalendarDay__defaultCursor {
  cursor: default
}
.CalendarDay__default {
  border: 1px solid #e4e7e7;
  color: #484848;
  background: #fff
}
.CalendarDay__default:hover {
  background: #e4e7e7;
  border: 1px solid #e4e7e7;
  color: inherit
}
.CalendarDay__hovered_offset {
  background: #f4f5f5;
  border: 1px double #e4e7e7;
  color: inherit
}
.CalendarDay__outside {
  border: 0;
  background: #fff;
  color: #484848
}
.CalendarDay__outside:hover {
  border: 0
}
.CalendarDay__blocked_minimum_nights {
  background: #fff;
  border: 1px solid #eceeee;
  color: #cacccd
}
.CalendarDay__blocked_minimum_nights:active,
.CalendarDay__blocked_minimum_nights:hover {
  background: #fff;
  color: #cacccd
}
.CalendarDay__highlighted_calendar {
  background: #ffe8bc;
  color: #484848
}
.CalendarDay__highlighted_calendar:active,
.CalendarDay__highlighted_calendar:hover {
  background: #ffce71;
  color: #484848
}
.CalendarDay__selected_span {
  background: #66e2da;
  border: 1px double #33dacd;
  color: #fff
}
.CalendarDay__selected_span:active,
.CalendarDay__selected_span:hover {
  background: #33dacd;
  border: 1px double #33dacd;
  color: #fff
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #00a699;
  border: 1px double #00a699;
  color: #fff
}
.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
  background: #b2f1ec;
  border: 1px double #80e8e0;
  color: #007a87
}
.CalendarDay__hovered_span:active {
  background: #80e8e0;
  border: 1px double #80e8e0;
  color: #007a87
}
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_calendar:active,
.CalendarDay__blocked_calendar:hover {
  background: #cacccd;
  border: 1px solid #cacccd;
  color: #82888a
}
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_out_of_range:active,
.CalendarDay__blocked_out_of_range:hover {
  background: #fff;
  border: 1px solid #e4e7e7;
  color: #cacccd
}
.CalendarDay__hovered_start_first_possible_end {
  background: #eceeee;
  border: 1px double #eceeee
}
.CalendarDay__hovered_start_blocked_min_nights {
  background: #eceeee;
  border: 1px double #e4e7e7
}
.CalendarMonth {
  background: #fff;
  text-align: center;
  vertical-align: top;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}
.CalendarMonth_table {
  border-collapse: collapse;
  border-spacing: 0
}
.CalendarMonth_verticalSpacing {
  border-collapse: separate
}
.CalendarMonth_caption {
  color: #484848;
  font-size: 18px;
  text-align: center;
  padding-top: 22px;
  padding-bottom: 37px;
  caption-side: initial
}
.CalendarMonth_caption__verticalScrollable {
  padding-top: 12px;
  padding-bottom: 7px
}
.CalendarMonthGrid {
  background: #fff;
  text-align: left;
  z-index: 0
}
.CalendarMonthGrid__animating {
  z-index: 1
}
.CalendarMonthGrid__horizontal {
  position: absolute;
  left: 9px
}
.CalendarMonthGrid__vertical,
.CalendarMonthGrid__vertical_scrollable {
  margin: 0 auto
}
.CalendarMonthGrid_month__horizontal {
  display: inline-block;
  vertical-align: top;
  min-height: 100%
}
.CalendarMonthGrid_month__hideForAnimation {
  position: absolute;
  z-index: -1;
  opacity: 0;
  pointer-events: none
}
.CalendarMonthGrid_month__hidden {
  visibility: hidden
}
.DayPickerNavigation {
  position: relative;
  z-index: 2
}
.DayPickerNavigation__horizontal {
  height: 0
}
.DayPickerNavigation__verticalScrollable_prevNav {
  z-index: 1
}
.DayPickerNavigation__verticalDefault {
  position: absolute;
  width: 100%;
  height: 52px;
  bottom: 0;
  left: 0
}
.DayPickerNavigation__verticalScrollableDefault {
  position: relative
}
.DayPickerNavigation__bottom {
  height: auto
}
.DayPickerNavigation__bottomDefault {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  display: flex;
  justify-content: space-between
}
.DayPickerNavigation_button {
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  border: 0;
  padding: 0;
  margin: 0
}
.DayPickerNavigation_button__default {
  border: 1px solid #e4e7e7;
  background-color: #fff;
  color: #757575
}
.DayPickerNavigation_button__default:focus,
.DayPickerNavigation_button__default:hover {
  border: 1px solid #c4c4c4
}
.DayPickerNavigation_button__default:active {
  background: #f2f2f2
}
.DayPickerNavigation_button__disabled {
  cursor: default;
  border: 1px solid #f2f2f2
}
.DayPickerNavigation_button__disabled:focus,
.DayPickerNavigation_button__disabled:hover {
  border: 1px solid #f2f2f2
}
.DayPickerNavigation_button__disabled:active {
  background: 0 0
}
.DayPickerNavigation_button__horizontalDefault {
  position: absolute;
  top: 18px;
  line-height: .78;
  border-radius: 3px;
  padding: 6px 9px
}
.DayPickerNavigation_bottomButton__horizontalDefault {
  position: static;
  margin: -10px 22px 30px
}
.DayPickerNavigation_leftButton__horizontalDefault {
  left: 22px
}
.DayPickerNavigation_rightButton__horizontalDefault {
  right: 22px
}
.DayPickerNavigation_button__verticalDefault {
  padding: 5px;
  background: #fff;
  box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
  position: relative;
  display: inline-block;
  text-align: center;
  height: 100%;
  width: 50%
}
.DayPickerNavigation_nextButton__verticalDefault {
  border-left: 0
}
.DayPickerNavigation_nextButton__verticalScrollableDefault,
.DayPickerNavigation_prevButton__verticalScrollableDefault {
  width: 100%
}
.DayPickerNavigation_svg__horizontal {
  height: 19px;
  width: 19px;
  fill: #82888a;
  display: block
}
.DayPickerNavigation_svg__vertical {
  height: 42px;
  width: 42px;
  fill: #484848
}
.DayPickerNavigation_svg__disabled {
  fill: #f2f2f2
}
.DayPicker {
  background: #fff;
  position: relative;
  text-align: left
}
.DayPicker__horizontal {
  background: #fff
}
.DayPicker__verticalScrollable {
  height: 100%
}
.DayPicker__hidden {
  visibility: hidden
}
.DayPicker__withBorder {
  box-shadow: 0 2px 6px rgba(0,0,0,.05),0 0 0 1px rgba(0,0,0,.07);
  border-radius: 3px
}
.DayPicker_portal__horizontal {
  box-shadow: none;
  position: absolute;
  left: 50%;
  top: 50%
}
.DayPicker_portal__vertical {
  position: initial
}
.DayPicker_focusRegion {
  outline: 0
}
.DayPicker_calendarInfo__horizontal,
.DayPicker_wrapper__horizontal {
  display: inline-block;
  vertical-align: top
}
.DayPicker_weekHeaders {
  position: relative
}
.DayPicker_weekHeaders__horizontal {
  margin-left: 9px
}
.DayPicker_weekHeader {
  color: #757575;
  position: absolute;
  top: 62px;
  z-index: 2;
  text-align: left
}
.DayPicker_weekHeader__vertical {
  left: 50%
}
.DayPicker_weekHeader__verticalScrollable {
  top: 0;
  display: table-row;
  border-bottom: 1px solid #dbdbdb;
  background: #fff;
  margin-left: 0;
  left: 0;
  width: 100%;
  text-align: center
}
.DayPicker_weekHeader_ul {
  list-style: none;
  margin: 1px 0;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px
}
.DayPicker_weekHeader_li {
  display: inline-block;
  text-align: center
}
.DayPicker_transitionContainer {
  position: relative;
  overflow: hidden;
  border-radius: 3px
}
.DayPicker_transitionContainer__horizontal {
  transition: height .2s ease-in-out
}
.DayPicker_transitionContainer__vertical {
  width: 100%
}
.DayPicker_transitionContainer__verticalScrollable {
  padding-top: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow-y: scroll
}
.DateInput {
  margin: 0;
  padding: 0;
  background: #fff;
  position: relative;
  display: inline-block;
  width: 130px;
  vertical-align: middle
}
.DateInput__small {
  width: 97px
}
.DateInput__block {
  width: 100%
}
.DateInput__disabled {
  background: #f2f2f2;
  color: #dbdbdb
}
.DateInput_input {
  font-weight: 200;
  font-size: 19px;
  line-height: 24px;
  color: #484848;
  background-color: #fff;
  width: 100%;
  padding: 11px 11px 9px;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid transparent;
  border-left: 0;
  border-radius: 0
}
.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: .2px;
  padding: 7px 7px 5px
}
.DateInput_input__regular {
  font-weight: auto
}
.DateInput_input__readOnly {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none
}
.DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #008489;
  border-left: 0
}
.DateInput_input__disabled {
  background: #f2f2f2;
  font-style: italic
}
.DateInput_screenReaderMessage {
  border: 0;
  clip: rect(0,0,0,0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px
}
.DateInput_fang {
  position: absolute;
  width: 20px;
  height: 10px;
  left: 22px;
  z-index: 2
}
.DateInput_fangShape {
  fill: #fff
}
.DateInput_fangStroke {
  stroke: #dbdbdb;
  fill: transparent
}
.DateRangePickerInput {
  background-color: #fff;
  display: inline-block
}
.DateRangePickerInput__disabled {
  background: #f2f2f2
}
.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb
}
.DateRangePickerInput__rtl {
  direction: rtl
}
.DateRangePickerInput__block {
  display: block
}
.DateRangePickerInput__showClearDates {
  padding-right: 30px
}
.DateRangePickerInput_arrow {
  display: inline-block;
  vertical-align: middle;
  color: #484848
}
.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #484848;
  height: 24px;
  width: 24px
}
.DateRangePickerInput_clearDates {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  padding: 10px;
  margin: 0 10px 0 5px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%)
}
.DateRangePickerInput_clearDates__small {
  padding: 6px
}
.DateRangePickerInput_clearDates_default:focus,
.DateRangePickerInput_clearDates_default:hover {
  background: #dbdbdb;
  border-radius: 50%
}
.DateRangePickerInput_clearDates__hide {
  visibility: hidden
}
.DateRangePickerInput_clearDates_svg {
  fill: #82888a;
  height: 12px;
  width: 15px;
  vertical-align: middle
}
.DateRangePickerInput_clearDates_svg__small {
  height: 9px
}
.DateRangePickerInput_calendarIcon {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  margin: 0 5px 0 10px
}
.DateRangePickerInput_calendarIcon_svg {
  fill: #82888a;
  height: 15px;
  width: 14px;
  vertical-align: middle
}
.DateRangePicker {
  position: relative;
  display: inline-block
}
.DateRangePicker__block {
  display: block
}
.DateRangePicker_picker {
  z-index: 1;
  background-color: #fff;
  position: absolute
}
.DateRangePicker_picker__rtl {
  direction: rtl
}
.DateRangePicker_picker__directionLeft {
  left: 0
}
.DateRangePicker_picker__directionRight {
  right: 0
}
.DateRangePicker_picker__portal {
  background-color: rgba(0,0,0,.3);
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%
}
.DateRangePicker_picker__fullScreenPortal {
  background-color: #fff
}
.DateRangePicker_closeButton {
  background: 0 0;
  border: 0;
  color: inherit;
  font: inherit;
  line-height: normal;
  overflow: visible;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  padding: 15px;
  z-index: 2
}
.DateRangePicker_closeButton:focus,
.DateRangePicker_closeButton:hover {
  color: darken(#cacccd,10%);
  text-decoration: none
}
.DateRangePicker_closeButton_svg {
  height: 15px;
  width: 15px;
  fill: #cacccd
}
:root {
  --sparky-anim-fade-quick: 0.15s;
  --sparky-anim-fade-long: 0.4s;
  --sparky-anim-move-quick: 0.15s;
  --sparky-anim-move-long: 0.4s;
  --sparky-anim-ease: ease;
  --sparky-border-width-sm: 1px;
  --sparky-border-width-md: 2px;
  --sparky-border-width-lg: 4px;
  --sparky-border-width-xl: 8px;
  --sparky-border-radius-md: 4px;
  --sparky-border-radius-lg: 8px;
  --sparky-border-radius-xl: 12px;
  --sparky-border-radius-xxl: 20px;
  --sparky-color-brand-red-50: #FFEBEF;
  --sparky-color-brand-red-100: #FFCED4;
  --sparky-color-brand-red-200: #F49A9E;
  --sparky-color-brand-red-300: #EC7378;
  --sparky-color-brand-red-400: #F85055;
  --sparky-color-brand-red-500: #FE3744;
  --sparky-color-brand-red-600: #EF333A;
  --sparky-color-brand-red-700: #DD2834;
  --sparky-color-brand-red-800: #C10D20;
  --sparky-color-brand-red-900: #A3000E;
  --sparky-color-brand-blue-50: #E2F2FF;
  --sparky-color-brand-blue-100: #BADDFF;
  --sparky-color-brand-blue-200: #8BC9FF;
  --sparky-color-brand-blue-300: #56B3FF;
  --sparky-color-brand-blue-400: #0091FF;
  --sparky-color-brand-blue-500: #206EF6;
  --sparky-color-brand-blue-600: #275CE3;
  --sparky-color-brand-blue-700: #2E38C3;
  --sparky-color-brand-blue-800: #1013A5;
  --sparky-color-brand-blue-900: #000642;
  --sparky-color-brand-green-50: #E6F5E6;
  --sparky-color-brand-green-100: #C2E5C1;
  --sparky-color-brand-green-200: #9AD499;
  --sparky-color-brand-green-300: #70C470;
  --sparky-color-brand-green-400: #4DB750;
  --sparky-color-brand-green-500: #24AA2E;
  --sparky-color-brand-green-600: #189C25;
  --sparky-color-brand-green-700: #008A1A;
  --sparky-color-brand-green-800: #00790B;
  --sparky-color-brand-green-900: #005B00;
  --sparky-color-brand-yellow-50: #FFFEE9;
  --sparky-color-brand-yellow-100: #FEFAC8;
  --sparky-color-brand-yellow-200: #FDF6A3;
  --sparky-color-brand-yellow-300: #FBF17F;
  --sparky-color-brand-yellow-400: #F9ED63;
  --sparky-color-brand-yellow-500: #F6E84A;
  --sparky-color-brand-yellow-600: #FCDD4B;
  --sparky-color-brand-yellow-700: #F9C542;
  --sparky-color-brand-yellow-800: #F6AE39;
  --sparky-color-brand-yellow-900: #F0872B;
  --sparky-color-brand-orange-50: #FAEBE7;
  --sparky-color-brand-orange-100: #FBD2BC;
  --sparky-color-brand-orange-200: #F9B590;
  --sparky-color-brand-orange-300: #F69A62;
  --sparky-color-brand-orange-400: #F4863D;
  --sparky-color-brand-orange-500: #F27512;
  --sparky-color-brand-orange-600: #E86F0D;
  --sparky-color-brand-orange-700: #DA6708;
  --sparky-color-brand-orange-800: #CC6006;
  --sparky-color-brand-orange-900: #B45303;
  --sparky-color-neutral-50: #F7F7F7;
  --sparky-color-neutral-100: #EEEEEE;
  --sparky-color-neutral-200: #E3E3E3;
  --sparky-color-neutral-300: #D1D1D1;
  --sparky-color-neutral-400: #ACACAC;
  --sparky-color-neutral-500: #8B8B8B;
  --sparky-color-neutral-600: #646464;
  --sparky-color-neutral-700: #515151;
  --sparky-color-neutral-800: #333333;
  --sparky-color-neutral-900: #131313;
  --sparky-color-neutral-white: #ffffff;
  --sparky-color-neutral-black: #000000;
  --sparky-color-transparent-25: rgba(0, 0, 0, .25);
  --sparky-color-transparent-50: rgba(0, 0, 0, .5);
  --sparky-color-transparent-90: rgba(0, 0, 0, .9);
  --sparky-color-other-blue: #00539b;
  --sparky-color-other-green: #377331;
  --sparky-color-other-yellow: #AF851A;
  --sparky-color-other-red: #d50032;
  --sparky-color-other-hover-1: #1F66E4;
  --sparky-color-other-hover-2: #D2E8FF;
  --sparky-color-other-hover-3: #E5E5E5;
  --sparky-color-other-hover-4: #EDF4FF;
  --sparky-color-other-hover-5: #ECECEC;
  --sparky-color-other-focus-1: #1E63DA;
  --sparky-color-other-focus-2: #CBE2FE;
  --sparky-color-other-focus-3: #DCDCDC;
  --sparky-color-other-focus-4: #E4EDFE;
  --sparky-color-other-focus-5: #E2E2E2;
  --sparky-l-max-width: 78.5rem;
  --sparky-l-linelength-width: 36rem;
  --sparky-box-shadow-sm: 0px 1px 2px 1px rgba(0, 0, 0, 0.2);
  --sparky-box-shadow-md: 0 3px 6px 0px rgba(0, 0, 0, 0.16);
  --sparky-size-base-unit: 0.5rem;
  --sparky-size-other-a: 1.7rem;
  --sparky-size-other-b: 4.625rem;
  --sparky-font-family-primary: euclid;
  --sparky-font-weight-regular: 400;
  --sparky-font-weight-medium: 500;
  --sparky-font-weight-bold: 700;
  --sparky-font-size-10: 0.625rem;
  --sparky-font-size-12: 0.75rem;
  --sparky-font-size-14: 0.875rem;
  --sparky-font-size-16: 1rem;
  --sparky-font-size-18: 1.125rem;
  --sparky-font-size-20: 1.25rem;
  --sparky-font-size-24: 1.5rem;
  --sparky-font-size-28: 1.75rem;
  --sparky-font-size-32: 2rem;
  --sparky-font-size-36: 2.25rem;
  --sparky-font-size-40: 2.5rem;
  --sparky-font-size-48: 3rem;
  --sparky-font-size-56: 3.5rem;
  --sparky-font-size-64: 4rem;
  --sparky-font-size-80: 5rem;
  --sparky-line-height-base: 1;
  --sparky-line-height-xss: 1.125;
  --sparky-line-height-xs: 1.2;
  --sparky-line-height-sm: 1.22222;
  --sparky-line-height-md: 1.25;
  --sparky-line-height-lg: 1.28571;
  --sparky-line-height-xl: 1.33333;
  --sparky-line-height-xxl: 1.4;
  --sparky-line-height-xxxl: 1.42857;
  --sparky-line-height-xxxxl: 1.5;
  --sparky-z-index-0: 0;
  --sparky-z-index-100: 100;
  --sparky-z-index-200: 200;
  --sparky-z-index-300: 300;
  --sparky-z-index-400: 400;
  --sparky-z-index-500: 500;
  --sparky-z-index-top: 99999;
  --sparky-z-index-bottom: -100;
  --sparky-theme-border-radius: 4px;
  --sparky-theme-border-width: 1px;
  --sparky-theme-color-body-foreground: #131313;
  --sparky-theme-color-body-foreground-inverted: #ffffff;
  --sparky-theme-color-body-background: #ffffff;
  --sparky-theme-color-body-background-inverted: #131313;
  --sparky-theme-color-primary-foreground: #206EF6;
  --sparky-theme-color-primary-foreground-hover: #1013A5;
  --sparky-theme-color-primary-foreground-inverted: #56B3FF;
  --sparky-theme-color-primary-foreground-inverted-hover: #206EF6;
  --sparky-theme-color-primary-background: #206EF6;
  --sparky-theme-color-primary-background-inverted: #56B3FF;
  --sparky-theme-color-primary-background-inverted-hover: #206EF6;
  --sparky-theme-color-primary-background-default: #ffffff;
  --sparky-theme-color-primary-background-hover: #EDF4FF;
  --sparky-theme-color-primary-background-focus: #E4EDFE;
  --sparky-theme-color-primary-background-disabled: #F7F7F7;
  --sparky-theme-color-primary-background-on-disabled: #ACACAC;
  --sparky-theme-color-primary-border: #206EF6;
  --sparky-theme-color-primary-border-hover: #1013A5;
  --sparky-theme-color-primary-border-inverted: #56B3FF;
  --sparky-theme-color-primary-border-inverted-hover: #206EF6;
  --sparky-theme-color-primary-default: #206EF6;
  --sparky-theme-color-primary-hover: #1F66E4;
  --sparky-theme-color-primary-focus: #1E63DA;
  --sparky-theme-color-primary-disabled: #F7F7F7;
  --sparky-theme-color-primary-on-disabled: #ACACAC;
  --sparky-theme-color-primary-active-default: #E2F2FF;
  --sparky-theme-color-primary-active-hover: #D2E8FF;
  --sparky-theme-color-primary-active-focus: #CBE2FE;
  --sparky-theme-color-primary-active-disabled: #F7F7F7;
  --sparky-theme-color-primary-active-on-disabled: #ACACAC;
  --sparky-theme-color-disabled-1: #F7F7F7;
  --sparky-theme-color-disabled-2: #ffffff;
  --sparky-theme-color-disabled-3: #ACACAC;
  --sparky-theme-color-disabled-foreground: #ACACAC;
  --sparky-theme-color-disabled-foreground-inverted: #646464;
  --sparky-theme-color-disabled-background: #E3E3E3;
  --sparky-theme-color-disabled-background-inverted: #333333;
  --sparky-theme-color-disabled-border: #E3E3E3;
  --sparky-theme-color-disabled-border-inverted: #646464;
  --sparky-theme-color-focus-ring: #206EF6;
  --sparky-theme-color-focus-ring-inverted: #ffffff;
  --sparky-theme-color-focus-1: #1E63DA;
  --sparky-theme-color-focus-2: #CBE2FE;
  --sparky-theme-color-focus-3: #DCDCDC;
  --sparky-theme-color-focus-4: #E4EDFE;
  --sparky-theme-color-focus-5: #E2E2E2;
  --sparky-theme-color-focus-6: #206EF6;
  --sparky-theme-color-hover-1: #1F66E4;
  --sparky-theme-color-hover-2: #D2E8FF;
  --sparky-theme-color-hover-3: #E5E5E5;
  --sparky-theme-color-hover-4: #EDF4FF;
  --sparky-theme-color-hover-5: #ECECEC;
  --sparky-theme-color-hover-6: #646464;
  --sparky-theme-color-link-foreground: #206EF6;
  --sparky-theme-color-link-foreground-hover: #56B3FF;
  --sparky-theme-color-link-foreground-inverted: #56B3FF;
  --sparky-theme-color-link-foreground-inverted-hover: #206EF6;
  --sparky-theme-color-neutral-subtle-foreground: #ACACAC;
  --sparky-theme-color-neutral-subtle-foreground-inverted: #333333;
  --sparky-theme-color-neutral-subtle-background: #EEEEEE;
  --sparky-theme-color-neutral-subtle-background-inverted: #333333;
  --sparky-theme-color-neutral-subtle-border: #E3E3E3;
  --sparky-theme-color-neutral-subtle-border-inverted: #333333;
  --sparky-theme-color-neutral-md-foreground: #ACACAC;
  --sparky-theme-color-neutral-md-foreground-inverted: #646464;
  --sparky-theme-color-neutral-md-background: #515151;
  --sparky-theme-color-neutral-md-background-inverted: #646464;
  --sparky-theme-color-neutral-md-border: #ACACAC;
  --sparky-theme-color-neutral-md-border-inverted: #646464;
  --sparky-theme-color-neutral-bold-foreground: #131313;
  --sparky-theme-color-neutral-bold-foreground-inverted: #ffffff;
  --sparky-theme-color-neutral-bold-background: #131313;
  --sparky-theme-color-neutral-bold-background-inverted: #ffffff;
  --sparky-theme-color-neutral-bold-border: #131313;
  --sparky-theme-color-neutral-bold-border-inverted: #ffffff;
  --sparky-theme-color-neutral-container-default: #F7F7F7;
  --sparky-theme-color-neutral-container-hover: #E5E5E5;
  --sparky-theme-color-neutral-container-focus: #DCDCDC;
  --sparky-theme-color-neutral-container-disabled: #F7F7F7;
  --sparky-theme-color-neutral-container-on-disabled: #ACACAC;
  --sparky-theme-color-neutral-background-default: #ffffff;
  --sparky-theme-color-neutral-background-hover: #ECECEC;
  --sparky-theme-color-neutral-background-focus: #E2E2E2;
  --sparky-theme-color-neutral-background-disabled: #ffffff;
  --sparky-theme-color-neutral-background-on-disabled: #ACACAC;
  --sparky-theme-color-neutral-border-default: #ACACAC;
  --sparky-theme-color-neutral-border-hover: #646464;
  --sparky-theme-color-neutral-border-focus: #206EF6;
  --sparky-theme-color-neutral-border-disabled: #E3E3E3;
  --sparky-theme-color-neutral-border-on-disabled: #ACACAC;
  --sparky-theme-color-text-highlight-foreground: #ffffff;
  --sparky-theme-color-text-highlight-background: #56B3FF;
  --sparky-theme-color-utility-info-foreground: #275CE3;
  --sparky-theme-color-utility-info-foreground-inverted: #275CE3;
  --sparky-theme-color-utility-info-background: #E2F2FF;
  --sparky-theme-color-utility-info-background-inverted: #206EF6;
  --sparky-theme-color-utility-info-border: #206EF6;
  --sparky-theme-color-utility-info-border-inverted: #206EF6;
  --sparky-theme-color-utility-success-foreground: #008A1A;
  --sparky-theme-color-utility-success-foreground-inverted: #008A1A;
  --sparky-theme-color-utility-success-light-foreground: #008A1A;
  --sparky-theme-color-utility-success-light-foreground-inverted: #008A1A;
  --sparky-theme-color-utility-success-background: #E6F5E6;
  --sparky-theme-color-utility-success-background-inverted: #377331;
  --sparky-theme-color-utility-success-border: #008A1A;
  --sparky-theme-color-utility-success-border-inverted: #008A1A;
  --sparky-theme-color-utility-warning-foreground: #AF851A;
  --sparky-theme-color-utility-warning-foreground-inverted: #AF851A;
  --sparky-theme-color-utility-warning-background: #FEFAC8;
  --sparky-theme-color-utility-warning-background-inverted: #FEFAC8;
  --sparky-theme-color-utility-warning-border: #F9C542;
  --sparky-theme-color-utility-warning-border-inverted: #F9C542;
  --sparky-theme-color-utility-error-foreground: #B45303;
  --sparky-theme-color-utility-error-foreground-inverted: #B45303;
  --sparky-theme-color-utility-error-background: #FAEBE7;
  --sparky-theme-color-utility-error-background-inverted: #FAEBE7;
  --sparky-theme-color-utility-error-border: #B45303;
  --sparky-theme-color-utility-error-border-inverted: #B45303;
  --sparky-theme-color-utility-sale-foreground: #C10D20;
  --sparky-theme-color-utility-sale-foreground-inverted: #C10D20;
  --sparky-theme-color-utility-sale-light-foreground: #DD2834;
  --sparky-theme-color-utility-sale-light-foreground-inverted: #DD2834;
  --sparky-theme-color-utility-sale-background: #FFEBEF;
  --sparky-theme-color-utility-sale-background-inverted: #FE3744;
  --sparky-theme-color-utility-sale-border: #FE3744;
  --sparky-theme-color-utility-sale-border-inverted: #FE3744;
  --sparky-theme-color-form-label-foreground: #131313;
  --sparky-theme-color-form-label-foreground-inverted: #ffffff;
  --sparky-theme-color-form-input-border: #ACACAC;
  --sparky-theme-color-form-input-border-hover: #646464;
  --sparky-theme-color-form-input-border-inverted-hover: #ffffff;
  --sparky-theme-color-form-input-border-focus: #131313;
  --sparky-theme-color-form-input-border-focus-inverted: #ffffff;
  --sparky-theme-color-form-input-border-error: #B45303;
  --sparky-theme-color-accordion-foreground-disabled: #ACACAC;
  --sparky-theme-color-accordion-background: #ffffff;
  --sparky-theme-color-accordion-background-hover: #ECECEC;
  --sparky-theme-color-accordion-background-focus: #E2E2E2;
  --sparky-theme-color-accordion-background-disabled: #ffffff;
  --sparky-theme-color-pagination-background: #ffffff;
  --sparky-theme-color-pagination-background-hover: #ECECEC;
  --sparky-theme-color-pagination-background-focus: #E2E2E2;
  --sparky-theme-color-pagination-background-disabled: #ffffff;
  --sparky-theme-color-pagination-background-on-disabled: #ACACAC;
  --sparky-theme-color-pagination-background-active: #E2F2FF;
  --sparky-theme-color-pagination-background-active-hover: #D2E8FF;
  --sparky-theme-color-pagination-background-active-focus: #CBE2FE;
  --sparky-theme-color-pagination-background-active-disabled: #F7F7F7;
  --sparky-theme-color-pagination-background-active-on-disabled: #ACACAC;
  --sparky-theme-color-text-link-foreground: #206EF6;
  --sparky-theme-color-text-link-foreground-hover: #1F66E4;
  --sparky-theme-color-text-link-foreground-focus: #1E63DA;
  --sparky-theme-color-text-link-foreground-disabled: #ACACAC;
  --sparky-theme-color-text-link-secondary: #131313;
  --sparky-theme-color-text-link-secondary-active: #206EF6;
  --sparky-theme-color-tooltip-background: #333333;
  --sparky-theme-color-tooltip-foreground: #ffffff;
  --sparky-theme-color-button-background: #ffffff;
  --sparky-theme-color-button-background-hover: #EDF4FF;
  --sparky-theme-color-button-background-focus: #E4EDFE;
  --sparky-theme-color-button-background-disabled: #F7F7F7;
  --sparky-theme-color-button-background-on-disabled: #ACACAC;
  --sparky-theme-color-button-background-active: #8BC9FF;
  --sparky-theme-color-button-foreground: #206EF6;
  --sparky-theme-color-button-foreground-hover: #206EF6;
  --sparky-theme-color-button-foreground-active: #206EF6;
  --sparky-theme-color-button-border: #ACACAC;
  --sparky-theme-color-button-primary-background: #206EF6;
  --sparky-theme-color-button-primary-background-hover: #1F66E4;
  --sparky-theme-color-button-primary-background-focus: #1E63DA;
  --sparky-theme-color-button-primary-background-disabled: #F7F7F7;
  --sparky-theme-color-button-primary-foreground: #ffffff;
  --sparky-theme-color-button-primary-foreground-hover: #ffffff;
  --sparky-theme-color-button-primary-foreground-active: #ffffff;
  --sparky-theme-color-button-primary-border: #206EF6;
  --sparky-theme-color-button-toolbar-background: #ffffff;
  --sparky-theme-color-button-toolbar-background-hover: #ECECEC;
  --sparky-theme-color-button-toolbar-background-focus: #E2E2E2;
  --sparky-theme-color-button-toolbar-background-active: #ffffff;
  --sparky-theme-color-button-toolbar-foreground: #131313;
  --sparky-theme-color-button-toolbar-foreground-hover: #131313;
  --sparky-theme-color-button-toolbar-foreground-active: #131313;
  --sparky-theme-color-button-toolbar-border: #ACACAC;
  --sparky-theme-color-button-toolbar-border-hover: #646464;
  --sparky-theme-color-button-toolbar-border-active: #646464;
  --sparky-theme-color-button-tabs-background: #ffffff;
  --sparky-theme-color-button-tabs-background-hover: #EDF4FF;
  --sparky-theme-color-button-tabs-background-focus: #E4EDFE;
  --sparky-theme-color-button-tabs-background-active: #E4EDFE;
  --sparky-theme-color-button-tabs-background-disabled: #ACACAC;
  --sparky-theme-color-button-active-foreground: #008A1A;
  --sparky-theme-color-button-active-foreground-hover: #008A1A;
  --sparky-theme-color-button-active-background: #ffffff;
  --sparky-theme-color-button-active-border: #008A1A;
  --sparky-theme-color-button-bare-foreground: #131313;
  --sparky-theme-color-button-bare-background: #ffffff;
  --sparky-theme-color-button-bare-background-hover: #ECECEC;
  --sparky-theme-color-button-bare-background-focus: #E2E2E2;
  --sparky-theme-color-button-bare-background-disabled: #ffffff;
  --sparky-theme-color-button-bare-background-on-disabled: #ACACAC;
  --sparky-theme-color-button-bare-background-active: #1013A5;
  --sparky-theme-color-button-modal-background: #F7F7F7;
  --sparky-theme-color-button-modal-background-hover: #E5E5E5;
  --sparky-theme-color-button-modal-background-focus: #DCDCDC;
  --sparky-theme-color-button-modal-background-disabled: #F7F7F7;
  --sparky-theme-color-button-modal-background-on-disable: #ACACAC;
  --sparky-theme-color-date-picker-range-default: #E2F2FF;
  --sparky-theme-color-date-picker-range-hover: #D2E8FF;
  --sparky-theme-color-date-picker-range-focus: #CBE2FE;
  --sparky-theme-color-date-picker-range-disabled: #F7F7F7;
  --sparky-theme-color-date-picker-range-on-disabled: #ACACAC;
  --sparky-theme-color-date-picker-range-selected-default: #206EF6;
  --sparky-theme-color-date-picker-range-selected-hover: #1F66E4;
  --sparky-theme-color-date-picker-range-selected-focus: #1E63DA;
  --sparky-theme-color-date-picker-single-default: #ffffff;
  --sparky-theme-color-date-picker-single-hover: #EDF4FF;
  --sparky-theme-color-date-picker-single-focus: #E4EDFE;
  --sparky-theme-color-date-picker-single-disabled: #F7F7F7;
  --sparky-theme-color-date-picker-single-on-disabled: #ACACAC;
  --sparky-theme-color-date-picker-single-selected-default: #206EF6;
  --sparky-theme-color-date-picker-single-selected-hover: #1F66E4;
  --sparky-theme-color-date-picker-single-selected-focus: #1E63DA;
  --sparky-theme-color-checkbox-border-default: #ACACAC;
  --sparky-theme-color-checkbox-border-hover: #646464;
  --sparky-theme-color-checkbox-border-focus: #206EF6;
  --sparky-theme-color-checkbox-border-disabled: #E3E3E3;
  --sparky-theme-color-checkbox-border-on-disabled: #ACACAC;
  --sparky-theme-color-checkbox-border-error: #B45303;
  --sparky-theme-color-checkbox-checked-default: #206EF6;
  --sparky-theme-color-checkbox-checked-hover: #1F66E4;
  --sparky-theme-color-checkbox-checked-focus: #1E63DA;
  --sparky-theme-color-checkbox-checked-disabled: #F7F7F7;
  --sparky-theme-color-checkbox-checked-on-disabled: #ACACAC;
  --sparky-theme-color-radio-border-default: #ACACAC;
  --sparky-theme-color-radio-border-hover: #646464;
  --sparky-theme-color-radio-border-focus: #206EF6;
  --sparky-theme-color-radio-border-disabled: #E3E3E3;
  --sparky-theme-color-radio-border-on-disabled: #ACACAC;
  --sparky-theme-color-radio-checked-default: #206EF6;
  --sparky-theme-color-radio-checked-hover: #1F66E4;
  --sparky-theme-color-radio-checked-focus: #1E63DA;
  --sparky-theme-color-radio-checked-disabled: #F7F7F7;
  --sparky-theme-color-radio-checked-on-disabled: #ACACAC;
  --sparky-theme-color-input-border-default: #ACACAC;
  --sparky-theme-color-input-border-hover: #646464;
  --sparky-theme-color-input-border-focus: #206EF6;
  --sparky-theme-color-input-border-disabled: #E3E3E3;
  --sparky-theme-color-input-border-on-disabled: #ACACAC;
  --sparky-theme-form-input-border-width: 1px;
  --sparky-theme-form-input-border-radius: 4px;
  --sparky-theme-box-shadow: 0 3px 6px 0px rgba(0, 0, 0, 0.16);
}

